import React from "react";

import {
  Card,
  CardContent,
  Typography,
  Grid
} from "@material-ui/core";

import { Update } from "../../Data/updates";

interface Props {
  updates: Update[];
}

const Interests: React.FC<Props> = (props: Props) => {
  return (
    <React.Fragment>
      {props.updates.map(update => {
        return (
          <Card
            variant="outlined"
            style={{ borderRadius: "6px" }}
          >
            <CardContent>
              <Grid container>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    color="primary"
                    variant="h5"
                  >
                    {update.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    gutterBottom
                  >
                    {update.date}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    align="justify"
                  >
                    {update.content}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      })}
    </React.Fragment>
  );
};

export default Interests;