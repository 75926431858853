// NOTE: Placing static data here while I develop the backend portion (server) of my website to actually store this and other data.

export interface Education {
  institution: string;
  icon: string;
  degree: string;
  start: string;
  end: string;
  description?: string;
}

const MY_EDUCATION: Education[] = [
  {
    institution: "The University of British Columbia",
    icon: "https://i.ibb.co/dBZSY6k/ubc.png",
    degree: "Bachelor of Science in Computer Science",
    start: "2017",
    end: "2021",
    description: "As of April 2020, I will have 3 classes left to graduate. Considering transitioning into a full-time job and taking one course at a time, to graduate in May 2021."
  },
  {
    institution: "Langara College",
    icon: "https://i.ibb.co/F8NB5Wf/langara.png",
    degree: "Associate Degree in Computer Science",
    start: "2015",
    end: "2017"
  },
  {
    institution: "Uni. Pontificia Bolivariana",
    icon: "https://i.ibb.co/yYFX61t/Escudo-f2.png",
    degree: "Bachelor's Degree in Industrial Engineering",
    start: "2013",
    end: "2014",
    description: "I took three full-time terms at Universidad Pontifica Bolivariana before coming to Canada."
  }
];

export default MY_EDUCATION;