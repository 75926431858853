import React from "react";
import {
  ThemeProvider,
  createMuiTheme
} from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import AppBar from "./components/AppBar";
import Router from "./components/Router";
import css from "../scss/main.module.scss";

const THEME = createMuiTheme({
  palette: {
    primary: {
      main: css.colorPrimary
    },
    secondary: {
      main: css.colorSecondary
    }
  },
  typography: {
    fontFamily: [
      "Montserrat", "sans-serif"
    ].join(",")
  }
});

export default () => {
  return (
    <ThemeProvider theme={THEME}>
      <BrowserRouter>
        <AppBar />
        <main className="main-content-wrapper">
          <Router />
        </main>
      </BrowserRouter>
    </ThemeProvider>
  )
};