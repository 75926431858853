import React from "react";
import {
  Avatar,
  Typography,
  Card,
  CardContent,
  Grid
} from "@material-ui/core";
import "./style.scss";

export default () => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid
          container
          alignItems="center"
        >
          <Grid
            item
            md={8}
            xs={12}
          >
            <Typography
              variant="h2"
              color="textPrimary"
            >
              Hello! I'm Ruben.
              </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >
              I'm a Frontend Engineer. Most of the time you'll find me talking to designers, sales staff, and other engineers to translate
              business logic into web experiences customers will love. Here you'll find details about my work experience,
              technical projects, and other interests. Do not hesitate in reaching out if you want to create something awesome together. <br />
              Have a great day!
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <Avatar
              alt="A picture of me at Stanley Park in December of 2019."
              classes={{
                root: "headshot-avatar"
              }}
              src="https://i.ibb.co/pK8RSzH/Photo-in-downtown-Vancouver.jpg"
              variant="circle"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}