import React from "react";
// LIBRARY COMPONENTS
import {
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import NavBar from "../NavBar";
import LinkPanel from "../LinkPanel";

import css from "../../../scss/main.module.scss";
import "./style.scss";

export default () => {
  const [displayLinkPanel, setDisplayLinkPanel] = React.useState(false);
  const [slideLinkPanelIn, setSlideLinkPanelIn] = React.useState(true);
  const [disableExpandIcon, setDisableExpandIcon] = React.useState(false);
  return (
    <React.Fragment>
      <div id="app-bar">
        <Typography
          variant="h5"
          color="secondary"
        >
          ruben programs
        </Typography>
        <NavBar />
      </div>
      {displayLinkPanel && slideLinkPanelIn ?
        <ExpandLessIcon
          id="expand-more-and-less-icon"
          className={disableExpandIcon ? "disabled" : ""}
          onClick={() => {
            if (disableExpandIcon) {
              return;
            }
            setSlideLinkPanelIn(false);
            setDisableExpandIcon(true);
            setTimeout(() => { setDisableExpandIcon(false) }, parseInt(css.linkPanelAnimationDurationMilliseconds)); // Wait until the link panel's animation has taken place
          }}
        />
        :
        <ExpandMoreIcon
          id="expand-more-and-less-icon"
          className={disableExpandIcon ? "disabled" : ""}
          onClick={() => {
            if (disableExpandIcon) {
              return;
            }
            if (!displayLinkPanel) {
              setDisplayLinkPanel(true);
              setDisableExpandIcon(true);
              setTimeout(() => { setDisableExpandIcon(false) }, parseInt(css.linkPanelAnimationDurationMilliseconds)); // Wait until the link panel's animation has taken place
            } else {
              setSlideLinkPanelIn(true);
              setDisableExpandIcon(true);
              setTimeout(() => { setDisableExpandIcon(false) }, parseInt(css.linkPanelAnimationDurationMilliseconds)); // Wait until the link panel's animation has taken place
            }
          }}
        />
      }
      {displayLinkPanel && <LinkPanel showAnimationOn={slideLinkPanelIn} />}
    </React.Fragment>
  );
};