import React from "react";
import {
  withRouter,
  Link
} from "react-router-dom";
// LIBRARY COMPONENTS
import {
  Typography
} from "@material-ui/core";

import "./style.scss";

interface Props {
  location: any;
};

const PATH_TO_ICON_MAP: { [path: string]: { display: string } } = {
  "/home": {
    display: "Home"
  },
  "/experience": {
    display: "Experience"
  },
  "/education": {
    display: "Education"
  },
  "/interests": {
    display: "Interests"
  }
};

const NavBar: React.FC<Props> = (props: Props) => {
  const CURRENT_PATH = props.location.pathname;
  return (
    <nav id="nav-bar-wrapper">
      {Object.keys(PATH_TO_ICON_MAP).map(path => {
        return (
          <Link
            key={path}
            to={path}
            className={`no-text-decoration nav-bar-item ${CURRENT_PATH === path ? "selected" : ""}`}
          >
            <Typography variant="body1">
              {PATH_TO_ICON_MAP[path].display}
            </Typography>
          </Link>
        );
      })}
    </nav>
  );
};

export default withRouter(NavBar);