import React from "react";
import {
  Card,
  Avatar,
  Grid,
  Typography,
  Box,
  Tooltip
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { Education } from "../../../../Data/education";
import "./style.scss";

interface Props {
  education: Education;
};

export default (props: Props) => {
  return (
    <Box
      width="100%"
    >
      <Card
        className="education-item-card"
      >
        <Grid
          container
          className="education-item-inner-wrapper"
        >
          <Grid
            item
            md={2}
            xs={12}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Avatar className="institution-icon" src={props.education.icon} />
            </Box>
          </Grid>
          <Grid
            item
            md={10}
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Typography
                variant="h6"
                color="primary"
              >
                {props.education.institution}
              </Typography>
              <Typography variant="subtitle1">
                {props.education.degree}
              </Typography>
              <Typography variant="body1">
                {`${props.education.start} - ${props.education.end}`}
              </Typography>
              {props.education.description &&
                <Tooltip
                  title={props.education.description}
                  classes={{ tooltip: "education-item-tooltip" }}
                >
                  <InfoIcon />
                </Tooltip>
              }
            </Box>
          </Grid>
        </Grid>
      </Card>

    </Box>
  );
};