import React from "react";
import EducationCard from "./components/EducationCard";
import { Education } from "../../Data/education";

interface Props {
  history: Education[];
}

export default (props: Props) => {
  return (
    <React.Fragment>
      {props.history.map((education: Education) => {
        return (
          <EducationCard
            education={education}
          />
        )
      })}
    </React.Fragment>
  );
};