import React from "react";
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import CodeIcon from '@material-ui/icons/Code';
import ExternalLink from "../../../components/ExternalLink";


import "./style.scss";

interface Props {
  showAnimationOn: boolean;
}

const LinkPanel: React.FC<Props> = (props: Props) => {

  return (
    <div
      id="link-panel"
      className={props.showAnimationOn ? "show" : "disappear"}
    >
      <ExternalLink
        className="link"
        href="www.github.com/rubenprograms"
        ariaLabel="Link to GitHub profile"
        materialIcon={<GitHubIcon fontSize="large" className="link-icon" />}
      />
      <ExternalLink
        className="link"
        href="www.codepen.io/rubenprograms"
        ariaLabel="Link to codepen profile"
        materialIcon={<CodeIcon fontSize="large" className="link-icon" />}
      />
      <ExternalLink
        className="link"
        href="www.linkedin.com/in/rubenprograms/"
        ariaLabel="Link to LinkedIn profile."
        materialIcon={<LinkedInIcon fontSize="large" className="link-icon" />}
      />
      <ExternalLink
        className="link"
        href="www.twitter.com/rubenprograms"
        ariaLabel="Link to LinkedIn profile."
        materialIcon={<TwitterIcon fontSize="large" className="link-icon" />}
      />
    </div>
  );
};

export default LinkPanel;