import React from "react";
import {
  IconButton,
  Link
} from "@material-ui/core";

interface Props {
  href: string;
  materialIcon: any;
  ariaLabel: string;
  className?: string;
}

export default (props: Props) => {
  return (
    <Link
      href={`${!props.href.match(/https?:\/\//) ? "https://" : ""}${props.href}`}
      target="_blank"
      rel="noopener"
      className={props.className}
    >
      <IconButton aria-label={props.ariaLabel}>
        {props.materialIcon}
      </IconButton>
    </Link>
  )
}