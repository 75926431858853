// NOTE: Placing static data here while I develop the backend portion (server) of my website to actually store this and other data.

export interface Update {
  title: string;
  date: string;
  content: string;
}

const MY_UPDATES: Update[] = [
  {
    title: "Welcome to my interests!",
    date: "Sun Apr 12 2020 18:55:08 GMT-0700 (Pacific Daylight Time)",
    content: "Hi there! On this page I will be sharing any Software Engineering areas I am currently interested in and exploring either through a course or a project. I have a long list of interests, including machine learning, site reliability engineering, and UI/UX design. Because I am working as a Frontend Engineer right now, I want to focus on UI/UX first. In all my roles in the past, I have always followed design from, well, the design team. And of course sometimes I do have to come up with some design and UX decisions to cover the ocassional situation their designs do not, but I have never actually done a significant amount of the design. I want to be able to do this. It might sound like a lie, but I honestly want to learn to make complex designs so I understand what is going on and so I can contribute better when creating user interfaces for clients, not only because it will increase my professional prospects - althought that is a very nice plus. As a last note, I designed the UI for this site, but I think I can improve it, so this site will probably change after I take a course or something. I will let you know if it does. Cheers. :)"
  }
];

export default MY_UPDATES;