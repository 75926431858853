import React from "react";
import JobCard from "./components/JobCard";
import { Job } from "../../Data/jobs";

interface Props {
   history: Job[];
}

export default (props: Props) => {
   return (
      <React.Fragment>
         {props.history.map(job => <JobCard job={job} />)}
      </React.Fragment>
   );
};