import React from "react";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Job } from "../../../../Data/jobs";
import { formatDate } from "../../../../utils/dates";
import "./style.scss";

interface Props {
  job: Job;
};

export default (props: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <ExpansionPanel
      className="job-card-panel"
      onChange={(event: object, expanded: boolean) => { setExpanded(expanded) }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />}>
        <Grid container>
          <Grid
            item
            md={2}
            xs={12}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%"
            >
              <Avatar
                classes={{ root: "company-icon" }}
                src={props.job.iconURI}
                alt={`icon for company ${props.job.company}`}
              />
            </Box>
          </Grid>
          <Grid
            item
            md={10}
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              className="job-summary-wrapper"
            >
              <Typography
                variant="h6"
                color="primary"
              >
                {props.job.position}
              </Typography>
              <Typography variant="subtitle1">
                {props.job.company} - {props.job.contractType}
              </Typography>
              <Typography variant="body1">
                {formatDate(props.job.start)} - {formatDate(props.job.end)}
              </Typography>
              <Typography variant="body1">
                {props.job.location}
              </Typography>
              {props.job.technologies &&
                <div>
                  {props.job.technologies.map(technology => {
                    return (
                      <Chip
                        label={technology}
                        size="small"
                        style={
                          {
                            opacity: "0.8",
                            marginRight: "5px",
                            marginTop: "5px",
                            backgroundColor: "#ffd21c"
                          }
                        }
                      />)
                  })}
                </div>
              }
              {!expanded &&
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <Typography color="primary">
                    ...learn more
                  </Typography>
                </Box>
              }
            </Box>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container>
          <Grid
            item
            md={12}
          >
            <Typography>
              {props.job.description}
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
          >
            <List>
              {props.job.details.map(detail => (
                <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <ListItemText
                    primary={detail}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};