// LIBRARY COMPONENTS
import React from "react";
import {
   Switch,
   Route,
   Redirect
} from "react-router-dom";
// MY COMPONENTS
import Experience from "../../../scenes/Experience";
import Home from "../../../scenes/Home";
import Education from "../../../scenes/Education";
import Interests from "../../../scenes/Interests";
// STATIC DATA (WHILE I DEVELOP THE BACKEND)
import MY_EDUCATION from "../../../Data/education";
import MY_JOBS from "../../../Data/jobs";
import MY_UPDATES from "../../../Data/updates";

export default () => {
   return (
      <Switch>
         <Route path="/home" component={Home} />
         <Route path="/experience" render={() => <Experience history={MY_JOBS} />} />
         <Route path="/education" render={() => <Education history={MY_EDUCATION} />} />
         <Route path="/interests" render={() => <Interests updates={MY_UPDATES} />} />
         <Redirect to="/home" />
      </Switch>
   );
};