import moment from "moment";

/**
 * Takes a date string in MM-DD-YYYYY format and returns a string to display in
 * format MMM DDD YYYY; if the date string is invalid, the string "Invalid date" 
 * will be returned.
 * @param date date string in MM-DD-YYYYY format
 */
export const formatDate = (date: string) => {
  let parsedDate = moment(date, "MM-DD-YYYY");
  if (!parsedDate.isValid()) {
    return "Invalid date."
  }
  if (moment().diff(parsedDate) < 0) {
    return "Present"
  }
  return parsedDate.format("MMM YYYY");
}